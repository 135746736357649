<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="body-con">
      <div class="top-tit">
        <div class="proinfo">
          <div class="join-us">帮助中心</div>
        </div>
      </div>
      
    </div>
    <div>
      <div class="mybtn" @click="toPlug">新手入门</div>
    </div>
    <div>
      <div class="mybtn" @click="toPlug2">使用教程</div>
    </div>
    <div>
      <div class="mybtn" @click="toPlug3">算法进阶</div>
    </div>
    <floor></floor>
  </div>
</template>

<script>


import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";

export default {
  components: {
    HeaderNav,
    Floor
  },
  data: () => ({
   
    
  }),
  methods: {

    toPlug() {
      window.location.href = "https://h5.lanmuda.com.cn/video/web/web.html";
    },
    toPlug2() {
      window.location.href = "https://h5.lanmuda.com.cn/video/web2/web2.html";
    },
    toPlug3() {
      window.location.href = "https://h5.lanmuda.com.cn/video/web3/web3.html";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}
/* required class */
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional */
.prism-editor__textarea:focus {
  outline: none;
}

/* not required: */

.body-con {
  width: 100%;
}
.top-tit {
  width: 100%;
  padding: 40px 0;
  min-height: 200px;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #020031, #6d3353);
}
.join-us {
  width: 100%;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  color: #ffffff;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  padding-right: 10px;
  justify-content: center;
}
.back-blue {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mybtn{
    margin: 30px auto 40px;
    padding: 13px 50px 17px;
    background-color: #337ab7;
    box-shadow: inset 0 -4px 0 #23527c;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
}
</style>